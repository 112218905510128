.reportSlicerRoot {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin: 12px 12px 0 0;
  padding: 2px;
  position: fixed;
  right: 0;
  row-gap: 5px;
}

.reportSlicerRoot .slicerHeader {
  align-items: stretch;
  background-color: var(--light-gray);
  border-radius: 10px;
  box-shadow: 2px 3px 4px var(--dark-gray);
  column-gap: 5px;
  display: flex;
  justify-content: flex-end;
  padding: 14px;
}

.reportSlicerRoot .slicerHeader svg {
  padding-bottom: 2px;
}

.reportSlicerRoot .slicerHeader a {
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
  text-decoration: underline;
  width: 50px;
}

.reportSlicerRoot .slicerDropdown {
  background-color: var(--light-gray);
  border-radius: 10px;
  box-shadow: 2px 4px 6px var(--dark-gray);
  display: flex;
  padding: 0;
}

.reportSlicerRoot .slicerDropdown .actionList {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  row-gap: 2px;
}

.reportSlicerRoot .slicerDropdown .actionList button {
  background-color: var(--blue);
  border: none;
  color: var(--white);
  cursor: pointer;
  margin: 0;
  padding: 10px;
}

.reportSlicerRoot .slicerDropdown .actionList button:first-child {
  border-top-left-radius: 5px;
}

.reportSlicerRoot .slicerDropdown .actionList .confirm {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  justify-content: flex-end;
}

.reportSlicerRoot .slicerDropdown .actionList .confirm button {
  background-color: var(--orange);
  border-bottom-left-radius: 5px;
  border-top-left-radius: 0;
  color: var(--white);
  font-weight: bold;
}

/* react-day-picker overrides */
.reportSlicerRoot .Selectable .DayPicker-Caption {
  font-size: 14px !important;
}

.reportSlicerRoot .Selectable .DayPicker-Weekdays {
  font-size: 12px !important;
}

.reportSlicerRoot .Selectable .DayPicker-Day {
  border-radius: 0 !important;
  font-size: 12px !important;
}

.reportSlicerRoot .Selectable .DayPicker-Day--today {
  color: var(--orange);
}

.reportSlicerRoot .Selectable .DayPicker-Day--selected {
  background-color: var(--orange);
  color: var(--white);
}

.reportSlicerRoot .Selectable .DayPicker-Day--start {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
  color: var(--white) !important;
}

.reportSlicerRoot .Selectable .DayPicker-Day--end {
  border-bottom-right-radius: 50% !important;
  border-top-right-radius: 50% !important;
  color: var(--white) !important;
}

.reportSlicerRoot .Selectable .DayPicker-Day--outside {
  background-color: var(--light-gray);
}

.reportSlicerRoot .Selectable .DayPicker-Day--start:hover,
.reportSlicerRoot .Selectable .DayPicker-Day--end:hover {
  background-color: var(--orange);
}

.reportSlicerRoot .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: var(--light-orange) !important;
  color: var(--orange);
}
