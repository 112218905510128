.loadingSpinnerRoot {
  animation: spin 2s linear infinite;
}

/* spinning animation: https://stackoverflow.com/a/16771693 */
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
