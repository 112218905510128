:root {
  --black: #002a3a;
  --dark-blue: #00445f;
  --blue: #005e85;
  --light-blue: #d1e8ee;
  --green: #9fad3e;
  --mid-green: #21ad7d;
  --dark-green: #3a772f;
  --orange: #d88920;
  --light-orange: #ffdbc5;
  --red: #ff4848;
  --teal: #52b7c9;
  --darker-gray: #8b8b8b;
  --dark-gray: #a1aaad;
  --medium-gray: #dbdbdb;
  --light-gray: #f5f5f5;
  --off-gray: #f4f7f8;
  --off-white: #e7eef0;
  --white: #fff;
}

body {
  background-color: var(--white);
  font-family: 'Arial', system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}
