.appRoot {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  width: 100%;
}

.appRoot .content {
  flex-grow: 1;
}

.appRoot .content .accessNeeded,
.appRoot .content .loggingIn {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.appRoot .content .accessNeeded img {
  height: fit-content;
  width: fit-content;
}

.appRoot .content .accessNeeded h2,
.appRoot .content .accessNeeded h3 {
  font-weight: normal;
}
