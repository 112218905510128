.reportPaneRoot {
  color: var(--dark-blue);
  display: flex;
  height: 100%;
}

.reportPaneRoot .sidebar {
  background-color: var(--white);
  border-right: 1px solid var(--medium-gray);
  display: flex;
  flex-direction: column;
  padding-top: 16px;
}

.reportPaneRoot .sidebar .sidebarItem {
  align-items: center;
  column-gap: 4px;
  cursor: pointer;
  display: flex;
  padding: 10px 32px 10px 16px;
}

.reportPaneRoot .sidebar .sidebarItem svg {
  font-size: 11px;
  width: 25px;
}

.reportPaneRoot .sidebar h3 {
  font-size: 11px;
  font-weight: normal;
  margin: 0;
}

.reportPaneRoot .sidebar .toggle {
  align-items: center;
  column-gap: 12px;
  display: flex;
  margin: 0 8px 8px 11px;
}

.reportPaneRoot .sidebar .toggle svg {
  cursor: pointer;
  font-size: 12px;
}

.reportPaneRoot .sidebar .toggle .loading svg {
  font-size: 14px;
}

.reportPaneRoot .expanded h2 {
  font-size: 11px;
  margin: 0;
}

.reportPaneRoot .expanded .toggle {
  margin-bottom: 10px;
  margin-top: -4px;
}

.reportPaneRoot .collapsed {
  padding-top: 16px;
}

.reportPaneRoot .collapsed h2 {
  font-size: 14px;
  margin: -4px 6px 0 6px;
  writing-mode: vertical-lr;
}

.reportPaneRoot .collapsed .toggle {
  margin-bottom: 16px;
}

.reportPaneRoot .sidebar .selected {
  background-color: var(--orange);
  color: var(--white);
}

.reportPaneRoot .content {
  flex-grow: 2;
}

.reportPaneRoot .content iframe {
  border-style: none;
}

.reportPaneRoot .reportError {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.reportPaneRoot .reportError h2 {
  font-weight: normal;
}

.reportPaneRoot .reportError span {
  font-size: 18px;
  font-weight: bold;
  margin-top: 0;
}

/* Animations */
@keyframes pulse {
  50% {
    opacity: 0;
  }
}
