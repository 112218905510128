.embeddedReportRoot,
.embeddedReportRoot .reportContent,
.embeddedReportRoot .embeddedReport {
  height: 100%;
}

.embeddedReportRoot .reportContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.embeddedReportRoot .embeddedReport {
  flex-grow: 2;
}

.embeddedReportRoot .reportError {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.embeddedReportRoot .reportError h2 {
  font-weight: normal;
}

.embeddedReportRoot .reportError span {
  font-size: 18px;
  font-weight: bold;
  margin-top: 0;
}

.embeddedReportRoot .overlay {
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  height: 99%;
  justify-content: center;
  padding-bottom: 50px;
  position: relative;
  width: 100%;
  z-index: 100;
}

.embeddedReportRoot .overlay img {
  animation: pulse 2s linear infinite;
}

.embeddedReportRoot .overlay span {
  font-size: 18px;
  font-weight: bold;
  margin-top: 16px;
}
