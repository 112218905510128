.reportNavigationRoot {
  align-items: center;
  background-color: var(--light-gray);
  border-bottom: 1px solid var(--medium-gray);
  display: flex;
  font-size: 11px;
  padding: 12px;
}

.reportNavigationRoot a {
  color: var(--dark-gray);
  cursor: pointer;
}

.reportNavigationRoot a:hover {
  text-decoration: underline;
}

.reportNavigationRoot .separator {
  color: var(--dark-gray);
  font-size: 6px;
  margin: 2px 10px 0 10px;
}
