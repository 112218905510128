.navBarRoot {
  align-items: center;
  background-color: var(--dark-blue);
  color: var(--white);
  column-gap: 30px;
  display: flex;
  line-height: 1.5;
  padding: 8px 24px;
}

.navBarRoot h1 {
  flex-grow: 2;
  font-size: 24px;
  font-weight: 600;
  line-height: 100%;
  margin: 0;
  text-transform: uppercase;
}

.navBarRoot img {
  height: 35px;
  padding-top: 7px;
}

.navBarRoot .feedbackLink a,
.navBarRoot .feedbackLink a:visited {
  color: var(--white);
  cursor: pointer;
  font-size: 14px;
  margin-right: 24px;
  padding: 0;
  text-decoration: none;
}

.navBarRoot .feedbackLink a svg {
  margin-right: 6px;
}

.navBarRoot .userDetails .userMenu {
  color: var(--white);
  cursor: pointer;
  font-size: 16px;
  padding: 0;
}

.navBarRoot .userDetails .userMenu:hover {
  background-color: var(--blue);
}

.navBarRoot .userDetails .menuOptions {
  background-color: var(--light-gray);
  border: 1px solid var(--medium-gray);
  border-radius: 2px;
  cursor: pointer;
  right: 0;
  z-index: 101;
}

.navBarRoot .userDetails .menuOptions a {
  color: var(--black);
}
